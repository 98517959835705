export const environment: any = {
	"production": true,
  "API": "https://api.fastportpassport.com/api/",
	"client_url": "https://my.govworks.com",
	"visas": false,
  "header": {
    "logo":  "assets/svg/fpp_logo.svg"
  },
  "support": {
    "phone": "8002201759"
  },
  "login": {
    "enabled": false,
    "logo": "assets/svg/Fastport_Logo_Dark.svg"
  },
	"legal_links": {
		"terms": "https://www.fastportpassport.com/terms-of-use",
		"privacy": "https://www.fastportpassport.com/privacy-policy",
    "refund": "https://www.fastportpassport.com/refund-policy/"
	},
  "source": {
    "domain": "fastpassport",
    "main_website": "https://www.fastportpassport.com",
    "promo_code": true,
    "name": "FastportPassport"
  },
  "footer": {
    "logo": "assets/svg/fpp_logo.svg",
    "logo_link":"https://www.fastportpassport.com",
    "copyright": "FastportPassport.com, LLC"
  },
  "landing_pages": {
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
	"create_lead": true,
	"seals": {
    "trsuted_site": true,
    "truste": "a8337496-f972-40ae-8341-777fef4dbbf1"
	},
	"summary_cta_icon": true,
	"livechat": {
		"enabled": true,
		"license_id": "1073051",
		"group": 16
	},
  "locations_page": {
    "enabled": false
  },
	"tags": {
		"analytics": "UA-15304416-1",
		"mouseflow": "78e031d8-7b12-4b4a-b707-87682c67bcce",
		"bing": "26085352",
		"adwords": "AW-995316786",
		"gtag_conv": "ym7vCLOQgJ0BELKozdoD"
	}
};